<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2023-09-27 19:42:21
 * @FilePath: \awx-ui\src\pages\appManage\addApp\addAppIndex.vue
-->
<template>
    <!-- <a-button @click="onCallBack">ooo</a-button> -->
  <appModal @onCallBack="onCallBack" />
</template>
<script>
import appModal from "./appModal/appModalIndex.vue";
export default {
  components: {
    appModal,
  },
  props: {
    creatMaster: Function,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onCallBack() {
      this.creatMaster()
    },
  },
};
</script>
<style lang="less" scoped>
</style>