<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2022-08-03 17:14:47
 * @LastEditTime: 2023-12-18 10:04:12
 * @FilePath: \awx-ui\src\pages\appManage\chooesApp.vue
-->
<template>
  <div class="template-list">
    <div class="system-template">
      <div v-if="systemTAppist.length >0" class="flex-warp">
        <div v-for="(item, index) in systemTAppist" :key="index" class="template-container">
          <chooesAppItem :appItem="item" :appCate='appCate' :selectAppId="selectAppId" :appType="'app'" :showPopId="showPopId" @showPop="showPop" @addApp="addApp" />
        </div>
      </div>
      <div v-else>
        <NoData />
      </div>
    </div>
  </div>
</template>
  <script>
import NoData from "@/components/noData";
import chooesAppItem from "./chooesAppItem.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import AddAppIndex from './addApp/addAppIndex.vue'
export default {
  components: { NoData, chooesAppItem },
  props: {
    systemTAppist: Array,
    appCate: Array
  },
  data() {
    return {
      selectAppId: "",
      showPopId: ""
    };
  },
  inject: ['getApp'],
  watch: {},
  computed: {
    ...mapState({
      task: (state) => state.task, //深拷贝的意义在于watch里面可以在Watch里面监听他的newval和oldVal的变化
    }),
  },
  methods: {
    showPop (id) {
      this.showPopId = id
    },
    // 新建应用
    addApp() {
      const self = this;
      let width = 740;
      let title = "";
      let content = (
        <AddAppIndex 
          creatMaster={() => {
            self.getApp();
          }} />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.template-list {
    max-height: calc(100vh - 212px);
    height: calc(100vh - 212px);
    overflow-y: auto;
    padding-right: 8%;
  .system-template,
  .custom-template {
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .template-container {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    margin: 8px 21px 8px 0;
    width: calc(20% - 16.8px);
    &:nth-child(5n) {
      margin: 8px 0 8px 0;
    }
  }
}
</style>