<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2023-09-27 17:18:20
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appTemplate\newTemplateShop\templateList.vue
-->
<template>
  <a-spin :spinning="spinning">
    <div class="app-template-list">
      <div v-if="templateList.length" class="card-list">
        <template v-for="(item, index) in templateList">
          <TemplateCard :key="index" :templateItem="item" @getSelectTemplateId="getSelectTemplateId" />
        </template>
      </div>
      <NODATA v-else type="normal" />
    </div>
  </a-spin>
</template>

<script>
import TemplateCard from "./templateCard.vue";
import NODATA  from '@/components/createTask/components/noData.vue'

export default {
  name: "TemplateList",
  data() {
    return {
      templateList: [],
      cateType: 'all',
      spinning: false
    };
  },
  components: { TemplateCard, NODATA },
  watch: {
  },
  computed: {
  },
  methods: {
    getSelectTemplateId (id) {
      this.templateList.forEach(item => {
        item.selected = false
        if (item.id === id) item.selected = true
      })
    },
    getTemplateList(searWoord, selectCate, selectTag, isMyCollect) {
      this.spinning = true
      let params = {};
      if (this.cateType === "all") params.keyword = searWoord || '';
      params.tag = selectTag || '';
      params.catagory = selectCate || '';
      this.$axiosGet(global.API.getTemplateList, params).then((res) => {
        this.spinning = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateList = res.data.results.map((item) => {
            return {
              ...item,
              selected: false
            };
          });
        }
      });
    },
  },
  mounted() {
    this.getTemplateList();
  },
  beforeDestroy() {
  },
};
</script>

<style lang="less" scoped>
.app-template-list {
  text-align: left;
  .current-selected {
    margin: 0 0 16px;
    .title {
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 12px;
    }
    /deep/ .ant-tag {
      border-radius: 4px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 400;
      background: rgb(225, 232, 247);
      .anticon-close {
        margin-left: 20px;
        width: 8px;
        height: 8px;
        margin-right: 4px;
        color: #777c88;
      }
    }
  }
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 520px;
    overflow-y: auto;
  }
}
</style>
