<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-08-21 11:24:58
 * @LastEditTime: 2023-09-27 17:17:06
 * @FilePath: \awx-ui\src\pages\appManage\addApp\appModal\appTemplate\newTemplateShop\templateCard.vue
-->
<template>
  <div class="app-template-card-page" @click="select">
    <svg-icon v-if="templateItem.selected" class="collect" icon-class="template-selected" />
    <div class="top">
      <div class="steps">
        <div class="steps-item" v-for="(item, index) in operations" :key="index">
          <div class="img" v-if="index === 0"><img :src="item.icon" alt /></div>
          <div class="img num" v-if="operations.length > 2 && index === 1">{{ item.length }}</div>
          <div class="img" v-if="operations.length > 2 && index === operations.length - 1"><img :src="item.icon" alt /></div>
          <div v-if="index < operations.length - 1"><svg-icon class="arrow" icon-class="arrow" /></div>
        </div>
      </div>
      <div style="height: 1px;background: #EDEFF3;margin: 0 20px; width: calc(100% - 40px);"></div>
    </div>
    <div class="name ell">{{ templateItem.name }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vmTemplateItem: {},
      selectTemplateId: ''
    };
  },
  props: {
    templateItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  inject: ['getSelectTemplateId'],
  computed: {
    operations() {
      let arr =_.cloneDeep(this.templateItem?.operations || [])
      if (arr.length <= 3) {
        return arr; // 如果数组长度小于等于3，则直接返回原数组
      } else {
        const middleItems = arr.splice(1, arr.length - 2); // 获取中间项，并从原数组中删除这些项
        return [arr[0], middleItems, arr[arr.length - 1]];
      }
    }
  },
  components: {},
  mounted() {},
  methods: {
    select () {
      console.log(this.templateItem)
      this.selectTemplateId = this.templateItem.id
      this.getSelectTemplateId(this.selectTemplateId)
      this.$emit('getSelectTemplateId', this.selectTemplateId)
    }
  }
};
</script>
<style scoped lang='less'>
.app-template-card-page {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 132px;
  background: #ffffff;
  border: 1px solid rgba(207, 213, 222, 1);
  cursor: pointer;
  border-radius: 6px;
  margin: 12px 17.35px 0 0;
  box-shadow: 0px 2px 10px 0px rgba(65,81,107,0.1);
  &:nth-child(4n) {
    margin-right: 0;
    box-shadow: none
  }
  .collect {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 60px;
    z-index: 10;
  }
  .name {
    text-align: center;
    font-size: 17px;
    color: #34343C;
    letter-spacing: 0;
    font-weight: 550;
    padding: 0 28px;
    margin-top: 16px;
  }
  .steps {
    display: flex;
    align-items: center;
    padding: 20px 28px 10px;
    &-item {
      display: flex;
      align-items: center;  
      .img {
        display: flex;
        width: 34px;
        height: 34px;
        padding: 0;
        background: #FFF; // 这个是图标的颜色定义
        // background: #FFFFFF;
        // border: 1px solid rgba(207,213,222,1);
        text-align: center;
        border-radius: 8px;
      } 
      .num {
        font-size: 24px;
        color: #3974F4;
        letter-spacing: 0;
        font-weight: 600;
        line-height: 14px;
      }
      img {
        width: 100%;
        border-radius: 8px;
      }
      .arrow {
        margin: 0 14px;
        color:  #CFD5DE;
      }
      &:nth-child(2n) {
        .img {
          background: #fff;
          border: 1px solid rgba(207,213,222,1);
          box-shadow: 0px 2px 10px 0px rgba(65,81,107,0.1);
          padding: 10px;
        }
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .btn {
    width: calc(100% - 40px);
    height: 32px;
    background: #F4F8FC;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    color:@primary-color;
    margin: 0 20px 20px;
  }
  &:hover {
    background: #F1F5FD;
    border: 1px solid rgba(57,116,244,1);
    .btn {
      color: #fff;
      background: #3974F4;
    }
    .arrow {
      color: #83A6F2;
    }
    .top {
      background: rgba(57,116,244,0.05);
    }
  }
}
@media (max-width: 1910px) {

}
</style>