<template>
  <div class="app-template-shop">
    <div class="template-header" :style="{'border-radius': '6px'}">
      <svg-icon :icon-class="'search'" style="font-size: 14px;color:#3D4E69" />
      <a-input class="search-input" v-model="searchWord" placeholder="搜索应用模版名称关键字" @change="changeWord"></a-input>
    </div>
    <TemplateList />
  </div>
</template>

<script>
import TemplateList from './newTemplateShop/templateList.vue'
import _ from "lodash";
const clientWidth = document.documentElement.clientWidth

export default {
  data() {
    return {
      clientWidth,
      isContract: false,
      searchWord: "",
      templateCate: [],
      templateTag: [],
      selectCate: '',
      selectTag: '',
      checked: false,
      isMyCollect: false,
      visible: false,
      taskModalTitleL: '新建任务',
      confirmLoading: false,
    };
  },
  components: { TemplateList },
  watch: {
  },
  mounted() {
    this.getCatagories();
    this.getTagsList();
  },
  methods: {
    debounce: _.debounce(function () {
      this.search()
    }, 1000),
    changeWord () {
      this.debounce()
    },
    search () {
      this.$EventBus.$emit("search", this.searchWord, this.selectCate, this.selectTag, this.isMyCollect);
    },
    select (item, type) {
      type === 'cate' ? this.selectCate = item.catagory : this.selectTag = item.tag
      this.search()
    },
    getCatagories() {
      this.$axiosGet(global.API.getCatagories, {
        page: 1,
        pageSize: 1000,
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = res.data.results;
          this.templateCate.unshift({
            catagory: '',
            name: '全部'
          })
        }
      });
    },
    getTagsList() {
      this.$axiosGet(global.API.getTagsList, {}).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateTag = res.data || [];
          this.templateTag.unshift({
            tag: '',
            name: '全部'
          })
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.app-template-shop {
  height: 660px;
  .template-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding: 0 20px;
    background: #fff;
    border: 1px solid rgba(207, 213, 222, 1);
    .search-input {
      border: none;
      background: #fff;
      height: 30px;
      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
  .template-cate {
    background: #FFFFFF;
    border: 1px solid rgba(207,213,222,1);
    border-radius: 6px;
    border-top: none;
  }
}
</style>