<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-26 19:22:45
 * @LastEditTime: 2024-03-29 11:17:01
 * @FilePath: \awx-ui\src\pages\appManage\chooesAppItem.vue
-->
<template>
  <div :class="['app-item', selectAppId === appItem.id ? 'app-item-selected' : '', appItem.id === 'add' && 'add-item']" @click.stop="selectedTemplate(appItem)">
    <svg-icon v-if="appItem.installed && appItem.installed > 0"  class="collect" icon-class="installed" />
    <span class="app-logo">
      <!-- 系统图标 -->
      <img v-if="appItem.id !== 'add' && !IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" :src="appItem.icon" alt />
      <!-- 纯色图标 -->
      <div v-if="appItem.id !== 'add' && IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)"  class='icon-item' :style="{
        'background': appItem.icon,
        'text-align': 'center',
        'width': '54px',
        'height': '54px',
        'border-radius': '8px',
        'margin': 'auto'
      }">
        <span :style="{
          'color': '#fff',
          'font-size': iconColorName(appItem.name).length === 1 ? '26px' : '20px',
          'width': '100%',
          'display': 'block',
          'height': '100%',
          'line-height': '54px'
        }">{{ iconColorName(appItem.name) }}</span>  
      </div>
      <!-- 网络图标 -->
      <iconifyIcon v-if="appItem.id !== 'add' && !IsColorIcon(appItem.icon) && IsNetWorkIcon(appItem.icon)" :icon="iconNetWorkName(appItem.icon)" style="font-size: 40px;" />
      <a-icon v-if="appItem.id === 'add'" type="plus" style="font-size: 38px;margin: auto;display: inline-block;color: #3974F4" />
    </span>
    <div v-if="appItem.id !== 'add'" class="app-text-info">
      <div class="app-title over-flow-ellipsis" :style="{'font-size': appType === 'app' ? '15px': '15px'}" :title="appItem.name">{{appItem.name}}</div>
      <p v-if="appItem.description" class="app-description over-flow-ellipsis" :title="appItem.description">{{appItem.description}}</p>
      <div class="app-categories" v-if="appItem.id !== 'add' && !appItem.description">
        <div class="app-categories-item" v-for="(item, index) in appcategories" :key="index">{{ handler(item)  }} <a-divider v-if="index !== appcategories.length - 1" type="vertical" /></div>
      </div>
    </div>
    <div class='pop-options' v-if="appItem.id !== 'add' && showPopId === appItem.id">
       <div v-for="(optItem, optIndex) in editOptions" class="option-item" :key="optIndex" @click.stop="hanlerOption(optItem)">{{optItem.name}}</div>
    </div>
  </div>
</template>
<script>
import DeleteApp from './deleteApp.vue'
import ImportStepModal from '@/components/importStep/index.vue'
export default {
  props: {
    appCate: Array,
    appItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectAppId: String,
    showPopId: String,
    appType: {
      type: String,
      default: "template",
    },
  },
  components: {
  },
  data() {
    return {
      editOptions: [
        {
          name: '编辑应用',
          type: 'edit'
        },
        // {
        //   name: '安装应用',
        //   type: 'install'
        // },
        {
          name: '删除应用',
          type: 'del'
        },
        {
          name: '新建步骤',
          type: 'add'
        },
        {
          name: '导入步骤',
          type: 'import'
        },
      ]  
    };
  },
  computed: {
    appcategories () {
      let arr = []
      arr = this.appItem.categories ? this.appItem.categories.split(',') : []
      return arr
    }
  },
  inject: ['getApp', "createTask"],
  methods: {
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName (name){
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    selectedTemplate(appItem) {
      if (this.appItem.id === 'add') this.$emit("addApp", this.appItem);
      if (this.appItem.id !== 'add') this.$emit("showPop", appItem.id);
    },
    showPop (appItem) {
      this.$emit("showPop", appItem.id);
    },
    hanlerOption (item) {
      if (item.type === 'del') {
        this.delApp()
      }
      if (item.type === 'add') this.createStep()
      if (item.type === 'import') this.importStep()
      if (item.type === 'edit') this.editApp()
    },
    editApp () {
      this.$router.push(`/app-manage/app-edit?id=${this.appItem.id}`)
    },
    // 导入步骤
    importStep () {
      const self = this;
      let width = 736;
      let title = "导入数据";
      let content = (
        <ImportStepModal
          appId={this.appItem.id}
          callBack={() => {
            console.log('a')
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
      this.$emit("showPop", '');
    },
    handler (item) {
      let str = ''
      let obj = undefined
      obj = this.appCate.find(child => child.catagory === item)
      str = obj ? obj.name : item
      return str
    },
    install (item){
      this.createTask(item)
    },
    createStep() {
      this.$router.push({
        name: "新建步骤",
        params: {
          appId: this.appItem.id
        }
      });
    },
    delApp () {
      const self = this;
      let width = 300;
      let content = (
        <DeleteApp
          sysTypeTxt="应用"
          sysType="当前"
          detail={this.appItem}
          callBack={() => {
            self.del(this.appItem)
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
      this.$emit("showPop", '');
    },
    del (item) {
      const self = this
      this.$axiosDelete(global.API.getApp + `/${item.id}`, {})
        .then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.$message.success("删除成功", 2);
            self.getApp()
          }
        })
        .catch((err) => {});
    }
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.app-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 94px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 2px 16px 0px rgba(65,81,107,0.1);
  background: #fff;
  padding: 20px 12px 20px 20px;
  color: #adb0b8;
  display: flex;
  border: 1px solid rgba(207,213,222,0.5);
  border-radius: 6px;
  &:hover {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 6px;
    .btn-install {
      color: #fff!important;
      background: #3d77f4;
      border: 1px solid rgba(57, 116, 244, 0.6);
      border: 1px solid rgba(57, 116, 244, 1);
    }
    .more-action {
      display: none;
    }
  }
  .collect {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 60px;
    z-index: 10;
  }
  .app-text-info {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-logo {
    height: 54px;
    margin: 0px 14px 0 0;
    width: 54px;
    min-width: 54px;
    line-height: 40px;
    background: #FFF; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    // border: 1px solid rgba(207,213,222,1);
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 54px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .app-title {
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #1d202d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
  }
  .app-description {
    margin-top: 6px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #7d838e;
    letter-spacing: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .app-categories {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-item {
      margin-top: 6px;
      font-size: 12px;
      color: #7d838e;
      display: inline-block;
    }
  }
  .more-action {
    display: none;
    width: 18px;
    height: 24px;
    background: #E7EBF4;
    border-radius: 4px;
    text-align: center;
  }
  // 选中的这个模板
  &-selected {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 6px;
  }
  .pop-options {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 100px;
    z-index: 1;
    width: 100%;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    box-shadow: 0px 3px 16px 0px rgba(0,0,0,0.2);
    .option-item {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 24px;
      font-size: 14px;
      color: #34343C;
      &:hover {
        background: #F3F6F9;
      }
    }
  }
}
.add-item {
  justify-content: center;
  .app-logo {
    background: #F2F5F9;
  }
}
</style>