<template>
  <div class="app-dialog">
    <div class="app-config">
      <!-- <div class="app-config-left">
        <div class="app-config-left-title">{{ stepsOptions.title }}</div>
        <div class="app-config-left-desc">{{ stepsOptions.desc }}</div>
        <a-steps class="app-config-left-list" direction="vertical" :current="step">
          <a-step :title="item.label" v-for="(item, index) in stepsOptions.stepList" :key="index" />
        </a-steps>
      </div>-->
      <div class="app-config-right">
        <div class="title">{{ stepsOptions.stepList[step].label }}</div>
        <!-- 标题 -->
        <div v-show="step === 0">
          <AppBaseInfo ref="AppBaseInfoRef" />
        </div>
        <!-- 第一步 -->
        <div v-show="step == 1">
          <AppTemplate />
        </div>
        <div class="app-right-bottom">
          <a-button :class="['btn-next','btn-step','mgr12', step === 0 && 'btn-cancel']" @click="upStep()">{{ step === 0 ? '取消' : '上一步' }}</a-button>
          <a-button class="btn-next btn-step" @click="nexStep()" type="primary">{{ (step === 0) ? "保存" :"下一步" }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import AppBaseInfo from "./appBaseInfo.vue";
import AppTemplate from "./appTemplate/index.vue";
export default {
  name: "ADDAPP",
  components: {
    AppBaseInfo,
    AppTemplate,
  },
  props: {},
  provide() {
    return {
      getSelectTemplateId: this.getSelectTemplateId,
    };
  },
  data() {
    return {
      step: 0,
      templateId: "",
      stepsOptions: {
        title: "新建应用",
        desc: "创建应用，并选择对应的安装模板",
        stepList: [
          { label: "设置应用基本信息", status: "setting" },
          { label: "选择应用安装模版", status: "notSet" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    refReshApp() {
      this.$emit("onCallBack");
    },
    getSelectTemplateId(id) {
      console.log(id, "template");
      this.templateId = id;
    },
    upStep() {
      if (this.step === 0) return this.$destroyAll();
      this.step--;
    },
    nexStep() {
      const self = this;

      self.$refs.AppBaseInfoRef.$refs.baseInfoFrom.validate(async (valid) => {
        if (valid) {
          if (!self.$refs.AppBaseInfoRef.form.icon) {
            self.$message.error("请上传产品图标");
          } else {
            const params = {
              ...self.$refs.AppBaseInfoRef.form,
              categories: self.$refs.AppBaseInfoRef.form.categories
                ? self.$refs.AppBaseInfoRef.form.categories.join(",")
                : "",
              tags: self.$refs.AppBaseInfoRef.form.tags
                ? self.$refs.AppBaseInfoRef.form.tags.join(",")
                : "",
            };
            this.$axiosJsonPost(global.API.getApp, params).then((res) => {
              if ([200, 201, 204, 202].includes(res.status)) {
                self.refReshApp();
                self.$destroyAll();
              }
            });
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.app-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .app-config {
    display: flex;
    height: 660px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    .app-config-left {
      width: 230px;
      padding-top: 40px;
      height: calc(100% + 43px);
      position: relative;
      top: -43px;
      &:first-child {
        background: #447af0;
        color: white;
        font-size: 14px;
        border-radius: 6px 0 0 6px;
      }
      &-title {
        font-size: 24px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        padding-left: 26px;
      }
      &-desc {
        margin: 20px 0 72px;
        padding-left: 26px;
        padding: 0 26px;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: 400;
      }
      &-list {
        padding-left: 26px;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 500;
        height: 36px;
        line-height: 36px;
        &-selected {
          background: rgba(255, 255, 255, 0.1);
          font-weight: 550;
        }
        /deep/ .ant-steps-item-title {
          color: #fff;
        }
        /deep/ .ant-steps-item-icon {
          // width: 26px;
          // height: 26px;
          // line-height: 26px;
          background: transparent;
          background-color: #fff;
          color: #3a74f4;
          .ant-steps-icon {
            color: #3a74f4;
          }
        }
        /deep/ .ant-steps-item-wait .ant-steps-item-icon,
        /deep/ .ant-steps-item-finish .ant-steps-item-icon {
          background: transparent;
          background-color: transparent;
          border: 1px solid #fff;
          .ant-steps-icon {
            color: #fff;
          }
        }
        /deep/ .ant-steps-item-container {
          height: 86px;
        }
        /deep/ .ant-steps-item-process .ant-steps-item-title {
          font-weight: 550;
        }
        /deep/ .ant-steps-item-tail {
          position: absolute;
          top: 37px;
          left: 16px;
          width: 1px;
          height: 40px;
          background: #fff;
          padding: 38px 0 6px;
        }
      }
    }
    .app-config-right {
      background: white;
      padding: 0px 40px 46px;
      color: black;
      font-size: 14px;
      position: relative;
      flex: 1;
      .app-right-bottom {
        position: absolute;
        bottom: 46px;
        right: 40px;
        .btn-step {
          width: 86px;
          height: 32px;
          border-radius: 4px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
        }
        .btn-next {
          background: #43b418;
          color: #ffffff;
          border-color: transparent;
          border: none;
        }
        .btn-cancel {
          background: #ecf1f7;
          font-size: 14px;
          color: #5a5a69;
        }
        .ant-btn[disabled] {
          color: #b2b5b9;
          background: #dee1e5;
        }
      }
      .title {
        font-size: 22px;
        color: #3a74f4;
        letter-spacing: 0;
        font-weight: 600;
        border-bottom: 1px solid #3a74f4;
        padding-bottom: 12px;
        margin-bottom: 16px;
      }
      div {
        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
  /deep/ .ant-form-explain {
    display: none;
  }
}
</style>